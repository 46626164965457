<template>
    <div class="banner" :style="{backgroundImage: `url(${bannerUrl})`}">
        <div class="content">
            <div
                class="banner-center"
                :style="{backgroundImage: `url(${bannerCenterUrl})`}"
            >
            <!-- <div
                class="category-button"
                v-clickReport="8123465623103488"
                @click="handleJump"
            >
                <div class="text-content">
                    立即选课
                </div>
                <div class="letter-content">
                    GO
                </div>
            </div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'OurdescTopHeader',
    serverCacheKey: props => props.bannerCenterUrl + props.bannerUrl,
    props: {
        bannerCenterUrl: {
            type: String,
            default: ''
        },
        bannerUrl: {
            type: String,
            default: ''
        },
    },
    methods: {
        handleJump() {
            this.$router.push({
                path: '/category'
            });
        }
    }
};
</script>
<style lang="less" scoped>
@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    25% {
        opacity: .25;
    }

    50% {
        opacity: .5;
    }

    75% {
        opacity: 1;
    }
}
@keyframes upanimate {
    from {
        height: 0;
        top: 576px;
    }

    to {
        height: 538px;
        top: 38px;
    }
}

.banner {
    width: 100%;
    height: 630px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;

    .content {
        position: relative;
        top: 64px;

        .banner-center {
            position: relative;
            top: 42px;
            margin: auto;
            width: 1200px;
            height: 545px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }

    .category-button {
        width: 230px;
        height: 64px;
        text-align: center;
        font-size: 22px;
        line-height: 64px;
        position: absolute;
        bottom: 170px;
        left: 0;
        overflow: hidden;
        cursor: pointer;
        display: flex;

        .text-content {
            width: 156px;
            color:  #1e212a;
            background: #fff;
            border-radius: 0 0 0 24px;
            border: solid #fff 2px;
            border-right: 0px;
            background: linear-gradient(to bottom,#fff,#FEFAF3);
        }

        .letter-content {
            width: 74px;
            color: #fff;
            background: #f73535;
            border-radius: 0 48px 48px 0;
        }

        &:hover {
            opacity: .9;
        }
    }
}
</style>
