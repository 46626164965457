<template>
    <!-- 优选名师 -->
    <div class="teacherInfo">
        <div class="teacherTitle">
            优选主讲老师 直播授课
        </div>
        <div class="teachersDisplayWrapper">
            <div>
                <!-- 上部分大图 -->
                <div class="gallery-top-box">
                    <div
                        class="swiper-container gallery-top"
                        v-swiper:galleryTopSwiper="galleryTopOptions"
                        ref="topSwiper"
                    >
                        <div class="swiper-wrapper">
                            <div
                                v-for="(item, index) in teacherList"
                                :key="index"
                                class="swiper-slide"
                            >
                                <div class="card">
                                    <div class="card-background">
                                    </div>
                                    <div
                                        class="teacher-cover"
                                        @click="toTeacherDetailPage(item.teacherNumber)"
                                    >
                                        <div
                                            class="teacher-img"
                                            :style="{backgroundImage: `url(${item.imagePhotoUrl})`}"
                                        >
                                        </div>
                                    </div>
                                    <div class="teacher-info">
                                        <div
                                            class="name"
                                            @click="toTeacherDetailPage(item.teacherNumber)"
                                        >{{item.teacherName}}</div>
                                        <div class="subjectDesc">{{item.subjectDesc}}</div>
                                        <div class="certificationNumber">{{`${item.certificationName|| '教资编号'}： ${item.certificationNumber}`}}
</div>
                                        <div class="tagList">
                                            <span
                                                v-for="(element, key) in item.tagList"
                                                :key="key"
                                                class="box active"
                                            >
                                                <div v-if="key === 0" class="tagImg"></div>
                                                <span class="text">{{element}}</span>
                                            </span>
                                        </div>
                                        <div
                                            v-if="item.introduction"
                                            class="introductions"
                                        >
                                            <div
                                                v-for="(element, key) in item.introduction
                                                    .filter(i => i.trim()).slice(0, 3)"
                                                :key="key"
                                            >
                                                <span class="icon"></span><span>{{element}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="controls-direction">
                        <div ref="leftButton" class="button button-left">
                            <div></div>
                        </div>
                        <div class="button button-right">
                            <div></div>
                        </div>
                    </div>
                </div>
                <!-- 下部分缩略图 -->
                <div class="gallery-thumbs-box">
                    <div
                        class="swiper-container gallery-thumbs"
                        v-swiper:galleryThumbsSwiper="galleryThumbsOptions"
                        ref="thumbSwiper"
                    >
                        <div class="swiper-wrapper" ref="thumbs">
                            <div
                                class="swiper-slide"
                                v-for="(item, index) in teacherList"
                                :key="index"
                            >
                                <div
                                    class="thumbs-item"
                                    :class="{'swiper-slide-active':thumbsActiveIndex === index}"
                                    :style="{backgroundImage: `url(${item.imagePhotoUrl})`}"
                                    @click="changeTeacher(index)"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

// eslint-disable-next-line import/no-commonjs
require('swiper/dist/css/swiper.css');

if (process.client) {
    const VueAwesomeSwiper = require('vue-awesome-swiper/dist/ssr');
    Vue.use(VueAwesomeSwiper);
}

export default {
    name: 'TeacherSwiper',
    props: {
        teacherList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        const self = this;
        return {
            thumbsActiveIndex: 0,
            thumbs: null,
            galleryThumbsOptions: {
                slidesPerView: 6,
                slidesPerGroup: 6,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                observer: true,
                observeParents: true,
                freeMode: true,
            },
            galleryTopOptions: {
                navigation: {
                    nextEl: '.button-right',
                    prevEl: '.button-left',
                    disabledClass: 'my-button-disabled',
                },
                observe: true,
                observeParents: true,
                simulateTouch: false,
                on: {
                    slideChangeTransitionEnd() {
                        try {
                            const leftButton = self.$refs.leftButton;
                            this.activeIndex === self.teacherList.length - 1
                                && leftButton.classList.add('button-disabled');
                            this.activeIndex < self.teacherList.length - 1
                                && leftButton.classList.remove('button-disabled');
                            const thumb = self.$refs.thumbSwiper.swiper;
                            const top =  self.$refs.topSwiper.swiper;
                            top.thumbs = thumb;
                            top.controller.control = thumb;
                            self.thumbsActiveIndex = this.activeIndex;
                        }
                        catch (error) {
                            // pass
                        }
                    }
                },
                preloadImages: true,
                updateOnImagesReady: true,
                lazyLoading: true,
                lazyLoadingInPrevNext: true,
                lazyLoadingOnTransitionStart: true,
            },
            realTeacherList: []
        };
    },
    methods: {
        // 点击缩略图切换大图
        changeTeacher(index) {
            const swiperTop = this.$refs.topSwiper.swiper;
            this.thumbsActiveIndex = index;
            const leftButton = this.$refs.leftButton;
            index < this.teacherList.length - 1
                && leftButton.classList.remove('button-disabled');
            swiperTop.slideTo(index);
        },
        // 去老师主页
        toTeacherDetailPage(number) {
            // const origin = window.location.origin;
            // window.open(`${origin}/teacher/detail/${number}`, '_blank');
        },
        // 优选名师动画
        teacherAnimation(dom) {
            // 整体内容区动画
            const options = {
                threshold: 0.25
            };
            const domIo = new IntersectionObserver(entries => {
                const visible = entries[0].isIntersecting;
                visible && dom.classList.add('animate-active');
            }, options);
            domIo.observe(dom);
            // 标题动画
            const titles = dom.querySelector('.titles');
            const titleIo = new IntersectionObserver(entries => {
                const visible = entries[0].isIntersecting;
                visible && titles.classList.add('title-active');
            });
            titleIo.observe(titles);
            const teacherTitle = dom.querySelector('.teacherTitle');
            const teacherTitleIo = new IntersectionObserver(entries => {
                const visible = entries[0].isIntersecting;
                visible && teacherTitle.classList.add('animate-active');
            });
            teacherTitleIo.observe(teacherTitle);
            // 轮播动效
            const galleryTop = dom.querySelector('.gallery-top-box');
            const galleryTopIo = new IntersectionObserver(entries => {
                const visible = entries[0].isIntersecting;
                visible && galleryTop.classList.add('animate-active');
            }, options);
            galleryTopIo.observe(galleryTop);
            // 缩略图动效
            const galleryThumbs = dom.querySelector('.gallery-thumbs-box');
            const galleryThumbsIo = new IntersectionObserver(entries => {
                const visible = entries[0].isIntersecting;
                visible && galleryThumbs.classList.add('animate-active');
            });
            galleryThumbsIo.observe(galleryThumbs);
            // // 查看更多按钮动效
            // const more = dom.querySelector('.more');
            // const moreIo = new IntersectionObserver(entries => {
            //     const visible = entries[0].isIntersecting;
            //     visible && more.classList.add('animate-active');
            // });
            // moreIo.observe(more);
        },
    }
};
</script>
<style lang="less" scoped>
// 轮播部分动画
@keyframes gallerytopup {
    from {
        top: 400px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}
@keyframes gallerythumsup {
    from {
        top: 120px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

.teacherInfo {
    display: flex;
    height: 670px;
    flex-direction: column;

    .teacherTitle {
        text-align: center;
        line-height: 45px;
        font-weight: 500;
        color: #1e212a;
        padding: 64px 0 54px;
        font-size: 32px;
        position: relative;
        opacity: 0;

        &.animate-active {
            animation: 1s gallerytopup ease-out;
            animation-fill-mode: forwards;
        }
    }
}

.teachersDisplayWrapper {
    position: relative;
    width: 1204px;
    max-width: 1360px;
    margin: 0 auto;
    padding-top: 26px;

    .swiper-container {
        height: 479px;
        overflow: hidden;
    }
}

.gallery-top-box {
    opacity: 0;
    position: relative;

    .swiper-wrapper {
        .swiper-slide {
            text-align: left;

            .card {
                position: relative;
                width: 1204px;
                height: 508px;

                .card-background {
                    position: absolute;
                    top: 65px;
                    width: 1204px;
                    height: 388px;
                    background: #fff;
                    border-radius: 20px;
                    box-shadow: 0 -25px 51px 0 #f5f6f8;
                    z-index: -1;
                }

                .teacher-cover {
                    position: relative;
                    left: 056px;
                    top: 14px;
                    display: inline-block;
                    width: 347px;
                    height: 415px;
                    border-radius: 20px;
                    background-color: #eef0f5;
                    overflow: hidden;
                    cursor: pointer;

                    .teacher-img {
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-position: center 20px;
                        background-size: contain;
                        transition: all .36s ease;

                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }

                .teacher-info {
                    position: absolute;
                    top: 67px;
                    display: inline-block;
                    padding-left: 112px;
                    color: #363636;
                    text-align: left;

                    .name {
                        font-size: 34px;
                        font-weight: 900;
                        line-height: 40px;
                        cursor: pointer;
                        display: inline-block;
                        padding-top: 75px;
                    }

                    .subjectDesc {
                        padding: 8px 0 0 16px;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 34px;
                        color: #707490;
                        display: inline-block;
                    }

                    .certificationNumber{
                        margin-top: 8px;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 22px;
                        color: #363636;
                    }

                    .tagList {
                        position: relative;
                        padding-bottom: 34px;

                        .icon {
                            position: relative;
                            top: -3px;
                            display: inline-block;
                            width: 36px;
                            height: 42px;
                            background-image: url(//i.gsxcdn.com/735759557_bzs94z4d.png);
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: contain;
                            transform: scale(.85);
                        }

                        .text {
                            vertical-align: top;
                        }

                        .tagImg {
                            width: 36px;
                            height: 42px;
                            background-image: url(https://i.gsxcdn.com/1717507689_1z1k2982.png);
                            background-size: 100%;
                            background-repeat: no-repeat;
                            display: inline-block;
                            margin-top: -3px;
                        }

                        .box {
                            display: inline-block;
                            margin-right: 14px;
                            padding: 0 16px;
                            height: 32px;
                            text-align: center;
                            vertical-align: middle;
                            font-size: 14px;
                            font-weight: 500;
                            color: #995b2e;
                            line-height: 32px;
                            background: linear-gradient(90deg, rgba(255, 225, 207, 1), rgba(247, 208, 168, 0.9));
                            border-radius: 14px 100px 100px;
                            margin-top: 25px;

                            &.active {
                                padding-left: 8px;
                            }
                        }
                    }

                    .introductions {
                        font-size: 16px;
                        color: #707490;
                        line-height: 34px;

                        div {
                            .icon {
                                position: relative;
                                top: 3px;
                                display: inline-block;
                                width: 12px;
                                height: 15px;
                                background-image: url(//i.gsxcdn.com/734999202_duq6dfw4.png);
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-size: contain;
                            }

                            span:nth-child(2) {
                                padding-left: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.animate-active {
        animation: 1s gallerytopup ease-out;
        // animation-delay: .2s;
        // -webkit-animation-delay: .2s;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
    }
}

.gallery-thumbs-box {
    opacity: 0;
    position: relative;
    height: 120px;
    overflow: hidden;

    .swiper-slide {
        width: 182px !important;
        height: 116px;
        margin-right: 23px;
        border-radius: 8px;
        overflow: hidden;

        .thumbs-item {
            width: 100%;
            height: 100%;
            background-color: #eef0f5;
            background-repeat: no-repeat;
            background-position: center 10px;
            background-size: 96px auto;
            transition: all .36s ease;
            -webkit-backface-visibility: hidden;
            -webkit-transform-style: preserve-3d;
            cursor: pointer;

            &:hover {
                transform: scale(1.05);
            }

            &.active {
                background-color: #fff;
            }

            &.swiper-slide-active {
                background-color: #fff;
            }
        }
    }

    &.animate-active {
        animation: .4s gallerythumsup ease-out;
        animation-delay: .2s;
        -webkit-animation-delay: .2s;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
    }
}

.controls-direction {
    position: absolute;
    right: -40px;
    top: 365px;
    height: 40px;
    z-index: 1;
    cursor: pointer;

    .button-left {
        position: relative;
        right: -3px;
        display: inline-block;
        width: 40px;
        height: 40px;
        background-color: #eef0f5;
        border-radius: 4px 0 0 4px;
        outline: none;

        &:hover {
            background-color: #dd2029;

            div {
                background-image: url(//i.gsxcdn.com/736209276_959v4h90.png);
            }
        }

        div {
            margin: auto;
            position: relative;
            top: 50%;
            margin-top: -12px;
            width: 24px;
            height: 24px;
            background-image: url(//i.gsxcdn.com/709414485_e7oloeom.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }

        &.button-disabled {
            background-color: #f5232d;

            div {
                background-image: url(//i.gsxcdn.com/736209276_959v4h90.png);
            }
        }
    }

    .button-right {
        position: relative;
        left: -2px;
        display: inline-block;
        width: 40px;
        height: 40px;
        background: #f5232d;
        border-radius: 0 4px 4px 0;
        outline: none;

        &:hover {
            background-color: #dd2029;
        }

        &.my-button-disabled {
            background-color: #eef0f5;

            div {
                background-image: url(//i.gsxcdn.com/851153463_bj6hl8ix.png);
            }
        }

        div {
            margin: auto;
            position: relative;
            top: 50%;
            margin-top: -12px;
            width: 24px;
            height: 24px;
            background-image: url(//i.gsxcdn.com/709414482_cuonxjvo.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
    }
}

.leftIcon {
    width: 142px;
    height: 136px;
    background-image: url(//i.gsxcdn.com/816902336_31mei9yr.gif);
    background-repeat: no-repeat;
    background-size: cover;
}

.rightIcon {
    width: 142px;
    height: 136px;
    background-image: url(//i.gsxcdn.com/816902336_31mei9yr.gif);
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
