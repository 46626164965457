<template>
	<div class="content">
		<div class="center-img" :style="{ backgroundImage: `url(${charact.imgUrl})` }"></div>
		<div class="classify">
			<div class="item" :class="{ leftIcon: index % 2 === 1 }" v-for="(item, index) in charact.classify" :key="index">
				<div class="icon-img" :style="{ backgroundImage: `url(${item.iconUrl})` }">
				</div>
				<div class="text">
					<div class="title">{{ item.title }}</div>
					<div v-for="(subTitle, i) in item.subTitle" :key="i" class="sub-title"
						:class="{ moreWidth: index === 0 }">
						{{ subTitle }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Charact",
	props: {
		charact: {
			type: Object,
			default: () => { }
		}
	},
	methods: {
		// 处理高途特色动画
		charactAnimation(dom) {
			const options = {
				threshold: 0.25
			}
			const domIo = new IntersectionObserver(entries => {
				const visible = entries[0].isIntersecting;
				visible && dom.classList.add('animate-active');
			}, options);
			domIo.observe(dom);
			const content = dom.querySelector('.content');
			const centerImg = content.querySelector('.center-img');
			const classify = content.querySelector('.classify');
			// const itemList = classify.querySelectorAll('.item');
			// 标题动画
			const titles = dom.querySelector('.titles');
			const titleIo = new IntersectionObserver(entries => {
				const visible = entries[0].isIntersecting;
				if (visible) {
					titles.classList.add('title-active');
					centerImg.classList.add('active');
					classify.classList.add('active');
				}
			}, options);
			titleIo.observe(titles);
		},
	}
}

</script>
<style lang="less" scoped>
@keyframes centerimgup {
	from {
		opacity: 0;
		top: 628px;
	}

	to {
		opacity: 1;
		top: 176px;
	}
}

@keyframes rowup {
	from {
		opacity: 0;
		// top: 10px;
	}

	to {
		opacity: 1;
		// top: 0;
	}
}

.content {
	width: 1280px;
	position: relative;
	margin: auto;

	.center-img {
		opacity: 0;
		position: absolute;
		left: 50%;
		top: 22px;
		margin-left: -358px;
		width: 716px;
		height: 628px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 90%;

		&.active {
			opacity: 1;
			animation: .8s centerimgup ease-out;
		}
	}

	.classify {
		opacity: 0;
		position: absolute;
		padding-top: 150px;
		width: 1280px;
		left: 50%;
		margin-left: -650px;


		&.active {
			animation: 1.4s rowup linear;
			animation-delay: .7s;
			-webkit-animation-delay: .7s;
			animation-fill-mode: forwards;
			-webkit-animation-fill-mode: forwards;
		}

		.item {
			position: relative;
			display: flex;
			width: 442px;
			height: 214px;

			.icon-img {
				width: 216px;
				height: 214px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 90%;
			}

			.text {
				text-align: left;
				position: relative;

				.title {
					padding: 26px 0 8px;
					font-size: 24px;
					color: #1e212a;
					font-weight: 500;
					line-height: 40px;
				}

				.sub-title {
					width: 150px;
					color: #6d718e;
					font-size: 14px;
					line-height: 24px;
				}

				.moreWidth {
					width: 155px;
				}
			}

			&.leftIcon {
				height: 214px;

				.icon-img {
					position: absolute;
					right: 19px;
				}

				.text {
					position: relative;
					right: -65px;

					.title {
						text-align: right;
						padding: 26px 24px 8px 0;
					}
				}
			}
		}

		.item:nth-child(1) {
			left: calc(50% - 113px);
			top: 46px;
			height: 0;
		}

		.item:nth-child(2) {
			float: left;
			left: 50px;
		}

		.item:nth-child(3) {
			float: right;
			right: 13px;

			.text {
				.sub-title {
					width: 154px !important;

				}
			}
		}

		.item:nth-child(4) {
			float: left;
			// left: -20px;
			margin-top: 40px;
		}

		.item:nth-child(5) {
			float: right;
			right: -33px;
			margin-top: 40px;
		}
	}
}
</style>
