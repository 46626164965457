<template>
    <div class="tech-content">
        <div
            class="sub-titles"
        >
            <div
                v-for="(item, index) in tech.classify"
                :key="index"
                class="tab-item"
                :class="{active: index === activeIndex}"
                @click="stopChangeTab(index)"
                @mouseenter="stopChangeTab(index)"
                @mouseleave="reStartChangeTab()"
            >
                <span>0{{index+1}}</span>
                <span class="text">{{item.title}}</span>
                <span class="shape"></span>
            </div>
        </div>
        <div class="current-intro">
            <div
                class="tab-img"
            >
                <div class="content">
                    <div class="title">{{tech.classify[activeIndex].title}}</div>
                    <div class="intro">{{tech.classify[activeIndex].intro}}</div>
                </div>
            </div>
            <div
                class="sub-img"
                :class="{
                    last: activeIndex === 4,
                    first: activeIndex === 0,
                    imgin: true
                }"
                :style="{backgroundImage: `url(${tech.classify[activeIndex].imgUrl1})`}">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Technology",
    props: {
        tech: {
            type: Object,
            default: () => {}
        }
    },
    // watch: {
    //     isTabView(val) {
    //         val && this.autoChangeTab();
    //     }
    // },
    data() {
        return {
            activeIndex: 0,
            isTabView: false,
            // 触发动画定时器的延时器
            techTimer: null,
            // 动画定时器
            timer: null
        }
    },
    mounted() {
        this.timer && clearInterval(this.timer);
        this.techTimer && clearInterval(this.techTimer);
    },
    beforeDestroy() {
        this.timer && clearInterval(this.timer);
        this.techTimer && clearInterval(this.techTimer);
    },
    methods: {
        // 科技赋能tab自动切换
        autoChangeTab() {
            const techEl = document.querySelector('.tech');
            const intro = techEl.querySelector('.current-intro');
            intro.classList.add('active');
            this.timer && clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.replay();
                if (this.activeIndex === 4) {
                    this.activeIndex = 0;
                }
                else {
                    this.activeIndex++;
                }
            }, 2000);
        },
        // 停止科技赋能tab自动切换
        stopChangeTab(index) {
            this.techTimer && clearTimeout(this.techTimer);
            this.timer && clearInterval(this.timer);
            const techEl = document.querySelector('.tech');
            const intro = techEl.querySelector('.current-intro');
            intro.classList.add('active');
            if (index !== this.activeIndex) {
                this.replay();
            }
            this.activeIndex = index;
        },
        // 重新加载动画
        replay() {
            const techEl = document.querySelector('.tech');
            const subImg = techEl.querySelector('.sub-img');
            subImg.className = 'sub-img';
            window.requestAnimationFrame(() => {
                window.requestAnimationFrame(() => {
                    techEl.querySelector('.sub-img').className = this.activeIndex === 0
                        ? 'sub-img first imgActive'
                        : this.activeIndex === 4 ? 'sub-img last imgActive' : 'sub-img imgActive';
                });
            });
        },
        // 鼠标离开重新启动tab自动切换
        reStartChangeTab() {
            this.autoChangeTab();
        },
        // 设置模块的位置以及动效
        techAndAnimation(dom) {
            // 整体内容区动画
            const options = {
                threshold: 0.5
            }
            // 标题动画
            const titles = dom.querySelector('.titles');
            const intro = dom.querySelector('.current-intro');
            const titleIo = new IntersectionObserver(entries => {
                const visible = entries[0].isIntersecting;
                visible && titles.classList.add('title-active');
            });
            titleIo.observe(titles);
            // 左侧tab动画
            const tabs = dom.querySelector('.sub-titles');
            const tabItems = tabs.querySelectorAll('.tab-item');
            const tabsIo = new IntersectionObserver((entries) => {
                const visible = entries[0].isIntersecting;
                if(visible) {
                    this.techTimer && clearTimeout(this.techTimer);
                    tabs.classList.add('animate-active');
                    intro.classList.add('active');
                    this.techTimer = setTimeout(() => {
                        this.autoChangeTab();
                    }, 3000);
                }
                else {
                    // 视区隐藏时清除定时器
                    this.techTimer && clearTimeout(this.techTimer);
                    this.timer && clearInterval(this.timer);
                }
            }, options);
            tabsIo.observe(tabs);
        }
    }
}

</script>
<style lang="less" scoped>
@keyframes subTitleleft {
    from {
        left: 0;
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes subTitleRight {
    from {
        left: 100px;
        opacity: 0;
    }

    to {
        left: 0;
        opacity: 1;
    }
}
@keyframes subImg {
    // 0% {
    //     opacity: 1;
    //     left: 0;
    // }
    from {
        opacity: 0;
        left: 100px;
    }

    to {
        opacity: 1;
        left: 0;
    }
}

.tech-content {
    width: 1200px;
    height: 584px;
    margin: auto;
    padding-top: 78px;

    .sub-titles {
        position: relative;
        float: left;

        &.animate-active {
            .tab-item {
                animation: 1s subTitleleft ease-out;
                animation-fill-mode: forwards;
                -webkit-animation-fill-mode: forwards;
            }
        }

        .tab-item {
            opacity: 0;
            position: relative;
            margin-bottom: 18px;
            padding: 36px 32px;
            width: 290px;
            border-radius: 18px;
            font-size: 24px;
            line-height: 33px;
            color: #9fa1b5;
            background-color: #f9fafc;
            font-weight: 500;
            cursor: pointer;

            &.active {
                background-color: #fff2f0;
                color: #fe684c;
                transition: background-color .3s ease;

                .shape {
                    background-image: url(//i.gsxcdn.com/1729215047_qjbi4r43.png);
                }
            }

            .text {
                padding: 0 14px 48px;
            }

            .shape {
                display: inline-block;
                float: right;
                width: 28px;
                height: 28px;
                background-image: url(//i.gsxcdn.com/706540905_0yp4vnr4.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }

        .tab-item:nth-child(1) {
            left: 100px;
        }

        .tab-item:nth-child(2) {
            left: 50px;
        }

        .tab-item:nth-child(4) {
            left: 50px;
        }

        .tab-item:nth-child(5) {
            left: 100px;
        }

    }

    .current-intro {
        opacity: 0;
        position: relative;
        width: 569px;
        height: 100%;
        text-align: left;
        float: right;

        .content {
            padding-top: 76px;
            margin-left: -181px;
        }

        .title {
            font-size: 32px;
            font-weight: 500;
            color: #1e212a;
            line-height: 45px;
            margin-bottom: 29px;
        }

        .intro {
            width: 310px;
            height: 66px;
            font-size: 18px;
            color: #6b6f8c;
            line-height: 33px;
        }

        .tab-img {
            position: absolute;
            right: 0;
            width: 433px;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 100%;
            background-image: url(https://i.gsxcdn.com/1729258972_l57ifw55.png);
        }

        .sub-img {
            position: absolute;
            width: 256px;
            height: 147px;
            background-repeat: no-repeat;
            background-size: contain;
            bottom: 55px;

            &.last {
                bottom: 15px;
            }

            &.first {
                bottom: 35px;
            }

            &.imgActive {
                animation: .5s subImg ease-out;
                // animation-delay: .4s;
            }

            // &.imgin {
            //     left: 0;
            //     opacity: 1;
            //     transition: left .5s, opacity .5s;;
            // }

            // &.imgout {
            //     left: 100px;
            //     opacity: 0;
            //     transition: left .5s, opacity .5s;;
            // }
        }

        &.active {
            animation: 1s subTitleRight ease-out;
            animation-delay: .4s;
            -webkit-animation-delay: .4s;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;

            // .sub-img {
            //     animation: 1s subTitleRight ease-out;
            //     animation-delay: .4s;
            //     -webkit-animation-delay: .4s;
            //     animation-fill-mode: forwards;
            //     -webkit-animation-fill-mode: forwards;
            // }
        }
    }
}
</style>
